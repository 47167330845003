.root {
  height: 300px;
  margin-top: 30px;
  // background: var(--color-gray-100);
  border: 1px solid var(--color-border-100);

  @media (min-width: 768px) {
    height: 300px;
  }
}

.arrow {
  padding: 20px;
}

.slide {
  height: 300px;
  padding: 30px;
  text-align: center;

  @media (min-width: 768px) {
    height: 300px;
    padding: 30px 50px;
  }
}

.inner {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

.quote {
  font-size: 15px;
  font-style: italic;

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

.type {
  margin-top: 30px;
  font-weight: 700;
  color: var(--color-secondary);
  text-transform: capitalize;
}

.year {
  font-family: var(--font-subheading);
  color: var(--color-gray-400);
}
