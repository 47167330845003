.image {
  position: relative;
  height: 500px;

  @media (min-width: 768px) {
    height: 90vh;
  }
}

.carousel {
  height: 500px;
  background: var(--color-gray-100);

  @media (min-width: 768px) {
    height: 90vh;
  }
}

.arrow {
  padding: 20px;

  @media (max-width: 767px) {
    display: none;
  }
}

.overlay {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;

  @media (min-width: 768px) {
    width: 66%;
    padding: 20px 60px;
  }

  h1 {
    font-size: 42px;
    letter-spacing: 1.5px;
    color: #fff;
    text-align: center;
    text-align: center;
    margin: 10px;

    @media (min-width: 768px) {
      font-size: 50px;
      text-align: left;
    }

    @media (min-width: 1200px) {
      font-size: 70px;
    }
  }

  p {
    font-size: 16px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 23px;
      text-align: left;
    }
  }
}

.text {
  position: relative;
  background: var(--color-overlay);
  max-width: 800px;
  padding: 20px;
  color: #fff;

  @media (min-width: 768px) {
    padding: 30px 50px;
    min-height: 450px;
  }
}

// Buttons

.btn {
  display: block;
  margin: 30px 0 20px;
  text-align: center;

  @media (min-width: 768px) {
    display: inline-block;
    margin: 20px 0;
  }
}

.btn_hide {
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  font-family: var(--font-heading);
  border: none;
  background: transparent;
  bottom: 10px;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  color: #fff;
  opacity: 0.6;
  font-size: 16px;

  @media (min-width: 768px) {
    bottom: 30px;
    left: auto;
    right: 20px;
    transform: none;
  }
}
