/* Shared */

h2 {
  text-align: center;
  font-size: 28px;
  margin: 0 0 50px;
  line-height: 1.2;
  color: var(--color-link);
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 36px;
  }
}

/* Why Join */

.join-section {
  padding: 60px 20px 80px;

  @media screen and (min-width: 768px) {
    padding: 80px 20px 120px;
  }

  .col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    border-radius: 2px;

    @media (min-width: 768px) {
      padding: 30px 50px;
    }

    h3 {
      margin: 0;
      font-size: 24px;
      text-align: center;
    }

    ul {
      padding-left: 20px;

      @media (min-width: 768px) {
        padding-left: 40px;
      }
    }

    li {
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 15px;
    }

    &.box-green {
      color: var(--color-background);
      background: var(--color-secondary);
      border: 1px solid var(--color-secondary);
    }

    &.box-white {
      border: 1px solid var(--color-border-100);
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: block;
      }
    }
  }
}

.join-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
}

/* Host Section */

.featured-hosts {
  padding: 60px 0;
  background: var(--color-gray-100);

  .host-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .host {
    position: relative;
    padding: 25px;
    border-radius: 2px;
    background: var(--color-background);
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--color-border-100);
    text-align: center;

    h4 {
      font-size: 20px;
      margin: 20px 0 0;
    }

    .image-container {
      position: relative;
      height: 100px;
      max-width: 180px;
      margin: auto;
    }
  }
}

.view-all {
  margin: 20px 0 30px;

  a {
    display: inline-block;

    &:hover {
      color: var(--color-background);
    }
  }
}
